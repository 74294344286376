body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .image-viewer {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-container {
    max-height: 90vh; /* Set the maximum height of the image container */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .image {
    max-height: 100%;
    object-fit: contain;
    border: 2px solid white;
    box-sizing: border-box;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;    
  }
  
  .prev-button,
  .next-button,
  .like-button,
  .dislike-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .prev-button:hover,
  .next-button:hover,
  .like-button:hover,
  .dislike-button:hover {
    background-color: #555;
  }
  
  .like-button {
    background-color: #52c41a; /* Green color for like button */
  }
  
  .dislike-button {
    background-color: #ff4d4f; /* Red color for dislike button */
  }